import React from 'react';

const aboutStyle = {
  color: '#080808',
  backgroundColor: '#D4E6F1',
  padding: '50px',
  minHeight: '500px',
  fontFamily: 'Calligraffitti',
  fontSize: '1.2em'
};

const paragraphStyle = {
  padding: '20px 0'
};


function About() {
  return (
    <div style={aboutStyle}>
      <p style={paragraphStyle}>
        Images in the pursuit of the beauty of natural form and function. They reveal the spirit beneath.
      </p>
      <p style={paragraphStyle}>
        All images by Robert Wray Smith unless otherwise noted.
        <br />Images by Robert Wray Smith © 2021 Robert Wray Smith.
        <br />Images by Maria Antonia Smith © 2021 Maria Antonia Smith.
        <br />Images by Joseph Mueller © 2021 Joseph Mueller.
      </p>
    </div>
  )
}

export default About;
  