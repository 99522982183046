import React from 'react';
import ternImage from '../images/WelcomeTern.jpg';

const containerStyle = {
    position: 'relative'
};

const imageStyle = {
    width: '100%',
    height: 'auto'
};

const quoteStyle = {
    position: 'absolute',
    margin: '20px 50px 20px',
    textAlign: 'center',
    fontFamily: 'Calligraffitti',
    fontSize: '2.0em',
    color: '#303030',
    bottom: '40px'
};

const attributionStyle = {
    position: 'absolute',
    bottom: '24px',
    width: '80%',
    textAlign: 'right',
    fontFamily: "Arial",
    fontStyle: 'italic',
    fontSize: '12',
    color: '#404040'
}

function Home() {
return (
    <div style={containerStyle}>
      <img src={ternImage} style={imageStyle} alt='Welcom Tern' />
      <h2 style={quoteStyle}>
        “&nbsp;Nature is the source of all true knowledge. She has her own logic, her own laws, she has no effect without cause nor invention without necessity.&nbsp;”
      </h2>
      <p style={attributionStyle}>
        ~ Leonardo da Vinci
      </p>
    </div>
  )
}

export default Home;
